import 'typeface-roboto/'

import './index.scss'

import React from 'react'
import { BrowserRouter, Route} from 'react-router-dom'

import Home from '../routes/home/Home'
import Choices from '../routes/parameters/choices/Choices'
import Settings from '../routes/settings/Settings'
import Form from '../routes/parameters/form/Form'
import Validation from '../routes/parameters/validation/Validation'
import Save from '../routes/parameters/saveasfavorite/Save'
import Chart from '../routes/recording/chart/Chart'
import Cockpit from '../routes/recording/cockpit/Cockpit'
import Finish from '../routes/recording/finish/Finish'
import Reports from '../routes/reports/Reports'

const getMachineOrigin = require('../util/get-machine-origin')

const machineIP = getMachineOrigin().split("/")[2];
const apiAddress = getMachineOrigin();

export default class Shell extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      parameters: {
        "general": {
          "project": "",
          "section":"",
          "client": "",
          "operator": "",
          "remarks":""
        },
        "machine": {
          "lubricator": true,
          "lubricant": "",
          "compressor": "",
          "aftercooler": false
        },
        "duct": {
          "installedIn": "",
          "type": "",
          "intDiameter": 0,
          "innerLayer": "",
          "configuration": "",
          "supplier": "",
          "identification": "",
          "temperature": 0
        },
        "cable": {
          "cable": "",
          "type": "",
          "amountOfFiber": 0,
          "supplier": "",
          "reel": "",
          "cableDiameter": 0,
          "cableHead": false,
          "meteringAtStart": 0,
          "meteringAtEnd": 0,
          "cableTemperature": 0,
          "fmaxSource": "",
          "fmax": 140,
          "radialForce": 0
        }
      },
      settings: {
        "appearence": {
          "language": "English"
        },
        "client": {
          "adressclient": "HMS MACHINES B.V.\nMarconistraat 113\n2809 PG GOUDA\nPays-Bas",
          "emailclient": "www.hms-machines.nl",
          "logo":"../../img/logos/hms.png"
        },
        "distributor": {
          "adressdistributor": "Plumettaz SA / Vetter GmbH Kabelverlegetechnik,\n Industriestrasse 28, \n D-79807 Lottstetten",
          "emaildistributor": "info@vetter-kabel.de"
        },
        "machine": {
          "serienumber": "0123456"
        }
      },
      recordingStatus: "Pause",
      realTimeData: {
        distance: 0,
        speed:0,
        blowing:0,
        force:0,
        temperature:0,
        duration:0,
        clamping:0
      },
      currentLocation: "/",
      reportData: [],
      recordedData: [],
      report: {},
      isOnline: 'no',
      isNewMeter: false
    }
    this.cloneFavorite = this.cloneFavorite.bind(this);
    this.updateParameter = this.updateParameter.bind(this);
    this.updateSetting = this.updateSetting.bind(this);
    this.updateLocation = this.updateLocation.bind(this);
    this.sendParametersToMachine = this.sendParametersToMachine.bind(this);
    this.connectionChange = this.connectionChange.bind(this);
    this.initiate = this.initiate.bind(this)

    this.previousDistance = 0
    this.isPingErrored = false
  }

  ping(callback) {
    const payload = {
      method: 'POST',
      body: JSON.stringify({data: 'isOnline'}),
      headers: {'Content-Type': 'application/json'}
    }
    return fetch(`${apiAddress}/isOnline`, payload)
      .then((raw) => raw.json())
      .then((response) => callback(null, response))
      .catch((error) => callback(error))
  }

  getCurrentReport(id) {
    const payload = {
      method: 'POST',
      body: JSON.stringify({reportId: id}),
      headers: {'Content-Type': 'application/json'}
    }
    return fetch(`${apiAddress}/getReport`, payload)
      .then((raw) => raw.json())
      .then((response) => {
        this.setState({
          report: response.report,
          realTimeData: {
            distance: 0,
            speed:0,
            blowing:0,
            force:0,
            temperature:0,
            duration:0,
            clamping:0
          }
        })
      })
      .catch((error) => console.error(error))
  }

  connectionChange (event) {
    if(event.type == 'offline') {
      if (this.connection) this.connection.close()
      this.setState({isOnline: 'no'})
      return
    }

    if(event.type == 'online') {
      this.setState({isOnline: 'wifi'})

      const scheduledPing = setInterval(() => {
        this.ping((error, response) => {
          if (error) {
            if (!this.isPingErrored) console.log('Ping failed', error)
            this.isPingErrored = true
            return
          } else {
            this.isPingErrored = false
          }

          this.setState({isOnline: 'server'})
          clearInterval(scheduledPing)

          this.setState({recordingStatus: response.recordingStatus})

          if (response.recordingStatus === 'Recording' || response.recordingStatus === 'Pause') {
            if (this.state.currentLocation === '/') {
              window.location.href = '/recording/chart'
            } else {
              this.getCurrentReport(response.currentReportId).then(() => {
                console.log('$$ report retrieved')
              })
            }
          } else {
            const isAtChart = this.state.currentLocation === '/recording/chart'
            const isAtCockpit = this.state.currentLocation === '/recording/cockpit'
            if (isAtChart || isAtCockpit) window.location.href = '/'
          }

          this.connection = new WebSocket('wss://' + machineIP)
          this.connection.onclose = (error) => {
            console.error('websocket closed', error)
            this.connectionChange({type: 'online'})
          }
          this.connection.onopen = () => {
            console.log('opening the websocket')
          }
          this.connection.onmessage = (message) => {
            let data = null
            try {
              data = JSON.parse(message.data)
            } catch (error) {
              return console.error(error)
            }

            let {recordingStatus, realTimeData, averageData} = data

            const wasAlreadyFinished = this.state.recordingStatus === 'Finish'
            if (wasAlreadyFinished && recordingStatus === 'Finish') return

            const state = {
              recordingStatus,
              maxForce: this.state.parameters.cable.fmax
            }

            if (realTimeData && recordingStatus === 'Recording') {
              
              state.maxForce = realTimeData.maxForce
              
              state.realTimeData = realTimeData
              
              state.isNewMeter = !!averageData
              
              if (averageData) {
                state.recordedData = this.state.recordedData
                  .concat([averageData])
              }

            }

            this.setState(state)

          }
        })
      }, 2000)

    }
  }

  componentDidMount () {
    let currentLocation = window.location.href.split('/')
    currentLocation.splice(0,3)
    currentLocation  = '/' + currentLocation.join('/')
    this.setState({currentLocation})

    if (navigator.onLine) this.connectionChange({type: 'online'})
    window.addEventListener('online', this.connectionChange, false)
    window.addEventListener('offline', this.connectionChange, false)
  }

  componentWillUnmount() {
    this.connection.close()
  }

  cloneFavorite(parameters) {
    this.setState({parameters})
  }

  updateLocation(url) {
    this.setState({ currentLocation: url})
  }

  initiate () {
    this.setState({
      recordingStatus: 'Pause',
      isNewMeter: false,
      report: {},
      recordedData: [],
      reportData: []
    })
  }

  updateParameter(event, category, type) {
    let keyToChange = event.target.name;
    let newParameters = this.state.parameters;
    switch (type) {
      case "text" :
        newParameters[category][keyToChange] = event.target.value;
        break;
      case "checkbox" :
        newParameters[category][keyToChange] = !this.state.parameters[category][keyToChange];
        break;
      default :
        newParameters[category][keyToChange] = event.target.value;
    }
    this.setState({
      parameters : newParameters
    })
  }

  updateSetting(event, category, type) {
    let keyToChange = event.target.name;
    let newSettings = this.state.settings;
    switch (type) {
      case "text" :
        newSettings[category][keyToChange] = event.target.value;
        break;
      case "checkbox" :
        newSettings[category][keyToChange] = !this.state.newSettings[category][keyToChange];
        break;
      default :
        newSettings[category][keyToChange] = event.target.value;
    }
    this.setState({
      settings : newSettings
    })
  }

  sendParametersToMachine() {
    let {report, parameters} = this.state
    let data = {}
    let url = ''
    if (report.parameters) {
      report.parameters = parameters
      data = {report}
      url = '/updateReports'
    } else {
      data = {parameters}
      url = '/newReport'
    }

    fetch(apiAddress + url, {
      method: 'POST',
      body: JSON.stringify({data}),
      headers: {"Content-Type": "application/json"}
    })
      .then((raw) => raw.json())
      .then((response) => {
        this.setState({report : response.newReport})
      })
      .catch((error) => console.error(error))
  }

  render() {
    const {parameters, realTimeData, recordingStatus, recordedData, reportData, isOnline, settings} = this.state;
    return (
      <BrowserRouter>
        <div className="fullwidth">
          <Route exact
            path=""
            render = { props =>
              <Home
                updateLocation = {this.updateLocation}
                isOnline = {isOnline}
                initiate = {this.initiate}
              />
            }/>
          <Route exact path="/parameters/choices"
            render = { props =>
              <Choices
                cloneFavorite = {this.cloneFavorite}
                updateLocation = {this.updateLocation}
              />
            }/>
          <Route exact path="/parameters/form/:category"
            render = { props =>
              <Form
                parameters = {parameters}
                updateParameter = {this.updateParameter}
                updateLocation = {this.updateLocation}
              />
            }/>
          <Route exact path="/parameters/validation"
            render = { props =>
              <Validation
                parameterNeeded = {parameters.cable.cableDiameter}
                updateLocation = {this.updateLocation}
                sendParametersToMachine = {this.sendParametersToMachine}
                isOnline = {isOnline}
              />
            }/>
          <Route exact path="/parameters/save-as-favorite"
            render = { props =>
              <Save
                parameters = {parameters}
                updateLocation = {this.updateLocation}
              />
            }/>
          <Route exact path="/recording/chart"
            render = { props =>
              <Chart
                parameters = {parameters}
                recordedData = {recordedData}
                realTimeData = {realTimeData}
                isNewMeter = {this.state.isNewMeter}
                maxForce = {this.state.maxForce}
                recordingStatus = {recordingStatus}
                updateLocation = {this.updateLocation}
                isOnline = {isOnline}
              />
            }/>
          <Route exact path="/recording/cockpit"
            render = { props =>
              <Cockpit
                parameters = {parameters}
                realTimeData = {realTimeData}
                maxForce = {this.state.maxForce}
                recordingStatus = {recordingStatus}
                updateLocation = {this.updateLocation}
                isOnline = {isOnline}
              />
            }/>
          <Route exact path="/recording/finish"
            render = { props =>
              <Finish
                parameters = {parameters}
                recordedData = {reportData}
                updateLocation = {this.updateLocation}
                updateParameter = {this.updateParameter}
                sendParametersToMachine = {this.sendParametersToMachine}
              />
            }/>
          <Route exact path="/reports"
            render = { props =>
              <Reports
                settings = {settings}
                updateLocation = {this.updateLocation}
                isOnline = {isOnline}
              />
            }/>
          <Route exact path="/settings"
          render = { props =>
            <Settings
              settings = {settings}
              updateLocation = {this.updateLocation}
              updateSetting = {this.updateSetting}
            />
          }/>
        </div>
      </BrowserRouter>
    )
  }
}
