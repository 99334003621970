import React from 'react'

const color={
  "Red":"#f44336",
  "Green":"#00964a",
  "Blue":"#2196f3",
  "Yellow":"#ffeb3b",
  "White":"#fff",
  "Grey":"#9e9e9e",
  "Brown":"#795548",
  "Violet":"#673ab7",
  "Turquoise":"#3ab7b7",
  "Black":"#000",
  "Orange":"#ffc107",
  "Pink":"#ff07d5",
  "2xRed":"#f44336",
  "2xGreen":"#00964a",
  "2xBlue":"#2196f3",
  "2xYellow":"#ffeb3b",
  "2xWhite":"#fff",
  "2xGrey":"#9e9e9e",
  "2xBrown":"#795548",
  "2xViolet":"#673ab7",
  "2xTurquoise":"#3ab7b7",
  "2xBlack":"#000",
  "2xOrange":"#ffc107",
  "2xPink":"#ff07d5"
}

const Select = (props) => {
  const {type, name, required, options} = props.inputdata;
  let red = props.isRed ? "red":"";
  let grey = required ? "" : "grey";
  const Options = options.map(option => {
    return (
      <option key={"option"+option} value={option}>{option}</option>
    )
  })
  return (
    <div className = "container-input" onClick = {((e) => document.querySelector("select[name="+name+"]").focus())}>
      <select
        name = {name}
        value={props.value}
        onChange = {props.handleChange}
        className = {red+" "+grey}
        >
        {Options}
      </select>
      <label className = "small">{props.label}</label>
    </div>
  )
}

class Radio extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      isSelected : false
    }
    this.handleClickInput = this.handleClickInput.bind(this)
  }

  handleClickInput(e){
    this.setState({isSelected: !this.state.isSelected})

  }

  render (){
    const {type, name, required, options, disabled} = this.props.inputdata;
    const value = this.props.value;
    let classToAdd = ""
    if (type === "number" || value) {
      classToAdd = "small"
    }
    let red = this.props.isRed ? "red":"";
    let grey = required ? "" : "grey";

    const Options = options.map(option => {

      const divColorStyle = {
        backgroundColor: color[option]
      }

      let divColor = name === "identification" ? <div className = "divColor" style={divColorStyle}></div> : "";

      return (
        <div key={option} className = "optionsRadio">
          <input
            type="radio"
            id={option}
            name={name}
            value={option}
            onChange = {this.props.handleChange}
          ></input>
          {divColor}
          <label for={option}>{option}</label>
        </div>
      )
    })
    const optionsShowed = this.state.isSelected ? <div className = "listOfOptions">{Options}</div> : "";
    const textInChooseButton = this.state.isSelected ? "Close" : "Choose from list ...";
    return (
      <div >
        <div className = "container-input-select">
          <div className = "container-input-and-label">
            <input
              name = {name}
              type = "text"
              required = {required}
              value = {value || ''}
              onChange = {this.props.handleChange}
              disabled = {disabled}
              required = {required}
              className = {red+" "+grey}
              onClick = {((e) => document.querySelector("input[name="+name+"]").focus())}
            ></input>
            <label className = {classToAdd}>{this.props.label}</label>
          </div>
          <div className = "choose-button" onClick = {this.handleClickInput}>
            <p>{textInChooseButton}</p>
          </div>
        </div>
        {optionsShowed}
      </div>
    )
  }

}


const Datalist = (props) => {
  const {type, name, required, options, disabled} = props.inputdata;
  const classToAdd = props.value ? "small" : "";
  const Options = options.map(option => {
    return (
      <option key={"option"+option} value={option}>{option}</option>
    )
  })
  let red = props.isRed ? "red":"";
  let grey = required ? "" : "grey";
  return (
    <div className = "container-input" onClick = {((e) => document.querySelector("input[name="+name+"]").focus())}>
      <input
        type="text"
        list={"list"+name}
        name = {name}
        value={props.value}
        onChange = {props.handleChange}
        disabled = {disabled}
        className = {red+" "+grey}
      ></input>
      <label className = {classToAdd}>{props.label}</label>
      <datalist id={"list"+name}>
        {Options}
      </datalist>
    </div>
  )
}

const Checkbox = (props) => {
  const {type, name, required, disabled} = props.inputdata;
  const classAdded = props.value ? "container-checkbox--blue" : "";
  return (
    <div className = {"container-checkbox "+classAdded} >
      <label>{props.label}</label>
      <input
        name = {name}
        type = "checkbox"
        checked = {props.value}
        onChange = {props.handleChange}
        disabled = {disabled}
      ></input>
    </div>
  )
}

const InputText = (props) => {
  const {type, name, required, disabled} = props.inputdata;
  const value = props.value;
  let classToAdd = ""
  if (type === "number" || value) {
    classToAdd = "small"
  }
  let red = props.isRed ? "red":"";
  let grey = required ? "" : "grey";
  return (
    <div className = "container-input" onClick = {((e) => document.querySelector("input[name="+name+"]").focus())}>
      <input
        name = {name}
        type = {type}
        required = {required}
        value = {value || ''}
        onChange = {props.handleChange}
        disabled = {disabled}
        required = {required}
        className = {red+" "+grey}
      ></input>
      <label className = {classToAdd}>{props.label}</label>
    </div>
  )
}

const Textarea = (props) => {
  const {type, name, required, disabled} = props.inputdata;
  const value = props.value;
  let classToAdd = ""
  if (type === "number" || value) {
    classToAdd = "small"
  }
  let red = props.isRed ? "red":"";
  let grey = required ? "" : "grey";
  return (
    <div className = "container-input" onClick = {((e) => document.querySelector("input[name="+name+"]").focus())}>
      <textarea
        name = {name}
        type = {type}
        required = {required}
        value = {value || ''}
        onChange = {props.handleChange}
        disabled = {disabled}
        rows="7"
        className = {red+" "+grey}
      ></textarea>
      <label className = {classToAdd}>{props.label}</label>
    </div>
  )
}

const Input = (props) => {
  if (props.inputdata.hide) {
    return ("")
  }
  switch (props.inputdata.type) {
    case "text" :
    case "number" :
      return (
        <InputText
          inputdata = {props.inputdata}
          label = {props.label}
          handleChange = {props.handleChange}
          value = {props.value}
          isRed = {props.isRed}
        />
      )
      break;

    case "checkbox" :
      return (
        <Checkbox
          inputdata = {props.inputdata}
          label = {props.label}
          handleChange = {props.handleChange}
          value = {props.value}
        />
      )
      break;

    case "select" :
      return (
        <Select
          inputdata = {props.inputdata}
          label = {props.label}
          handleChange = {props.handleChange}
          value = {props.value}
          isRed = {props.isRed}
        />
      )
      break;

    case "datalist" :
      return (
        <Datalist
          inputdata = {props.inputdata}
          label = {props.label}
          handleChange = {props.handleChange}
          value = {props.value}
          isRed = {props.isRed}
        />
      )
      break;

    case "textarea" :
      return (
        <Textarea
          inputdata = {props.inputdata}
          label = {props.label}
          handleChange = {props.handleChange}
          value = {props.value}
          isRed = {props.isRed}
        />
      )
      break;

    case "radio" :
      return (
        <Radio
          inputdata = {props.inputdata}
          label = {props.label}
          handleChange = {props.handleChange}
          value = {props.value}
          isRed = {props.isRed}
        />
      )
      break;

    default:
      return (
        <InputText
          inputdata = {props.inputdata}
          label = {props.label}
          handleChange = {props.handleChange}
          value = {props.value}
          isRed = {props.isRed}
        />
      )
  }
}

export default Input;
