import './style.scss'

import React from 'react'
import {getData, getUsers} from './services'
import {ButtonHome} from '../../util/ButtonHome'
import {withRouter} from 'react-router-dom'
import en from './en.json'
import Input from '../../util/Input'
import settingsTextEn from '../../texts/settings-en.json'
import settingsSource from './settings.json'
// import image from "../../img/logos/hms.png"

const text = en;
const settingsText = settingsTextEn;

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      metrics: {},
      loaded: false,
      user: null,
      error: null
    }
    this.gotoHome = this.gotoHome.bind(this)
  }

  componentDidMount() {
    getData()
      .then(metrics => {
        this.setState({metrics, loaded: true})
      })
      .catch(error => this.setState({
        error: JSON.stringify(error),
        loaded: false
      }))
  }

  gotoHome () {
    this.goto("/")()
  }

  goto (href) {
    return () => {
      this.props.history.push(href);
      this.props.updateLocation(href)
    }
  }

  render() {
    const sections = ["appearence", "client", "distributor","machine"];
    const Sections = sections.map(item => {
      const Inputs = settingsSource[item].map(input => {
        if(input.type === "image") {
          return (<img src = {require("../../img/logos/hms.png")}/>)
        }
        return (
          <Input
            inputdata = {input}
            label = {settingsText[item][input.name]}
            value = {this.props.settings[item][input.name]}
            handleChange = {((e) => this.props.updateSetting(e, item, input.type))}
            key = {input.name}
          />
        )
      } )
      return (
        <div key = {item}>
          <h3>{settingsText[item].title}</h3>
          <br/>
          {Inputs}
          <hr/>
        </div>
      )
    })
    const board = this.state.loaded ? <Dashboard metrics = {this.state.metrics} error = {this.state.error}/> : ""
    return (
      <div className = "grid-main">
        <div className = "header-right pos-align-center">
          <ButtonHome onClick = {this.gotoHome}/>
        </div>
        <div className = "content-left pos-align-top">
          <h2 className = "secondary-color">{text.title}</h2>
        </div>
        <div className = "content-center">
          {Sections}
          <br/>
          <hr/>
          <br/>
          {board}
        </div>
      </div>
    )
  }
}

const Dashboard = (props) => {
  const {metrics, error} = props;

  const networkInterfaces = metrics.os.networkInterfaces

  const netInterfaces = []

  for (const key in networkInterfaces) {
    if (networkInterfaces.hasOwnProperty(key)) {
      const data = networkInterfaces[key]
        .filter(elt => {return elt.internal === false && elt.family === 'IPv4'})
        .map(elt => {return {address: elt.address, mac: elt.mac}})
      if (data.length) {
        netInterfaces.push({
          name: key,
          data: networkInterfaces[key]
            .filter(elt => {return elt.internal === false && elt.family === 'IPv4'})
            .map(elt => {return {address: elt.address, mac: elt.mac}})
        })
      }
    }
  }

  const Error = error ? <p>{error}</p> : <p>No Error</p>
  return (
    <div className = "light-color">
      <h4>Error : </h4>
      {Error}
      <h4>Tablet : </h4>
      <p>{process.env.NODE_ENV}</p>
      <br/>
      <h4>Internet network Test</h4>
      <br/>
      <h4>Machine : </h4>
      <p>Health</p>
      <div>
        <span>Disk </span>
        <span>{metrics.health.disk.usage}</span>
        <span> used of </span>
        <span>{metrics.health.disk.size}</span>
      </div>
      <div>
        <span>Memory </span>
        <span>{metrics.health.memory.usage}</span>
        <span> used of </span>
        <span>{metrics.health.memory.size}</span>
      </div>
      <div>
        <span>CPU </span>
        <span>Core: {metrics.health.cpu.quantity}, loads: </span>
        <span>{metrics.health.cpu.loads.join(', ')}</span>
      </div>

      <h4>OS</h4>
      <div>Arch: {metrics.os.arch}</div>
      <div>Platform: {metrics.os.platform}</div>
      <div>Release: {metrics.os.release}</div>
      <div>Hostname: {metrics.os.hostname}</div>
      <div>
        <div>Network Interfaces:</div>
        <div>{buildNetworkInterfacesUI(netInterfaces)}</div>
      </div>

      <h4>Process</h4>
      <div>cwd: {metrics.process.cwd}</div>
      <div>
        <div>Env:</div>
        <div>{buildEnvUI(metrics.process.env)}</div>
      </div>
      <div>
        <div>Memory usage:</div>
        <div>&nbsp;&nbsp; RSS {metrics.process.memoryUsage.rss}</div>
        <div>&nbsp;&nbsp; Heap total {metrics.process.memoryUsage.heapTotal}</div>
        <div>&nbsp;&nbsp; Heap used {metrics.process.memoryUsage.heapUsed}</div>
        <div>&nbsp;&nbsp; External {metrics.process.memoryUsage.external}</div>
      </div>
      <div>PID: {metrics.process.pid}</div>
      <div>Arch: {metrics.process.arch}</div>
      <div>Platform: {metrics.process.platform}</div>
      <div>Version: {metrics.process.version}</div>
      <div>
        <div>Versions:</div>
        <div>{buildVersionsUI(metrics.process.versions)}</div>
      </div>
    </div>
  )
}

function buildNetworkInterfacesUI(netInterfaces) {
  const spans = []
  netInterfaces.forEach((elt, id) => {
    const key = `net-${id}`
    elt.data.forEach(e => {
      spans.push(<div key={key}>&nbsp;&nbsp; {e.address} [{elt.name}]</div>)
    })
  })
  return spans
}

function buildEnvUI(env) {
  const spans = []

  for (const variable in env) {
    if (env.hasOwnProperty(variable)) {
      if (isWhiteListed(variable)) {
        const key = `env-${variable}`
        spans.push(<div key={key}>&nbsp;&nbsp; {variable}: {env[variable]}</div>)
      }
    }
  }

  return spans
}

function buildVersionsUI(versions) {
  const spans = []

  for (const version in versions) {
    if (versions.hasOwnProperty(version)) {
      const key = `versions-${version}`
      spans.push(<div key={key}>&nbsp;&nbsp; {version}: {versions[version]}</div>)
    }
  }

  return spans
}

function isWhiteListed(variable) {
  const strictWhiteList = [
    'LANG',
    'LANGUAGE',
    'npm_config_node_version',
    'npm_config_user_agent',
    'npm_package_version'
  ]

  const partialWhiteList = [
    'npm_package_dependencies',
    'npm_package_devDependencies',
    'BUMBLEBEE',
    'NODE'
  ]

  const isInPartialWhiteList = partialWhiteList.some(whiteListItem => {
    return variable.startsWith(whiteListItem)
  })

  return strictWhiteList.includes(variable) || isInPartialWhiteList
}

export default withRouter(Settings);
