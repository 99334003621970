import './style.scss'

import React from 'react'
import en from './en.json'
const text = en
// TODO text[measure]

const Chart = require('chart.js')


export default class Graphs extends React.Component {
  constructor (props) {
    super(props)
    this.previousLength = 0
    this.maxDistance = 0
  }

  shouldComponentUpdate(nextProps, nextState) {

    // Do not update if a new meter is not reached
    if (!this.props.isNewMeter) return false

    const numberOfPoints = this.props.recordedData.length
    const lastIndex = numberOfPoints - 1
    const speed = this.props.recordedData[lastIndex].speed
    // If lots of points and speedy only update for odd number of points
    if (numberOfPoints > 400 && speed > 40 && numberOfPoints % 2 === 0) {
      return false
    } else {
      return true
    }
  }

  componentDidUpdate() {
    const data = this.props.recordedData
    if (!data.length || this.previousLength === data.length) return
    this.previousLength = data.length

    const distance = data[data.length - 1].distance
    const force = data[data.length - 1].force
    const speed = data[data.length - 1].speed
    const blowing = data[data.length - 1].blowing

    this.update(this.myChart.data, distance, force, speed, blowing)

    this.myChart.update()
  }

  componentDidMount() {
    const canvas = document.getElementById('myChart')

    const data = {
      labels: [],
      datasets: [
        {
          label: 'Force',
          yAxisID: 'y-axis-force',
          borderColor: '#F88181',
          borderWidth: 1,
          fill: false,
          data: []
        },
        {
          label: 'Speed',
          yAxisID: 'y-axis-speed',
          borderColor: '#7AC9A8',
          borderWidth: 1,
          fill: false,
          data: []
        },
        {
          label: 'Blowing',
          yAxisID: 'y-axis-blowing',
          borderColor: '#6AA6E6',
          borderWidth: 1,
          fill: false,
          data: []
        }
      ]
    }

    const recordedData = this.props.recordedData

    recordedData.forEach((e) => {
      this.update(data, e.distance, e.force, e.speed, e.blowing)
    })
    
    const options = {
      // Way better performance if animation is diabled
      animation: false,
      events: [],
      elements: {
        // Line tension to 0 disables smoothing of curves, better performance
        line: {tension: 0},
        point:{radius: 0}
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          ticks: {
            maxTicksLimit: 10,
            maxRotation: 0
          }
        }],
        yAxes: [
          {
            id: 'y-axis-force',
            display: true,
            position: 'left',
            ticks: {min: 0, max: 150, stepSize: 15, fontColor: '#F88181'}
          },
          {
            id: 'y-axis-speed',
            display: true,
            position: 'right',
            gridLines: {drawOnChartArea: false},
            ticks: {min: 0, max: 100, stepSize: 10, fontColor: '#7AC9A8'}
          },
          {
            id: 'y-axis-blowing',
            display: true,
            position: 'right',
            gridLines: {drawOnChartArea: false},
            ticks: {min: 0, max: 20, stepSize: 2, fontColor: '#6AA6E6'}
          }
        ]
      }
    }

   this.myChart = new Chart(canvas, {type: 'line', data, options})

   if (this.props.logContainerRef) {
     this.props.logContainerRef(canvas)
   }
  }

  update(data, distance, force, speed, blowing) {
    if (distance > this.maxDistance && speed !== null) {
      this.maxDistance = distance
      data.labels.push(distance)
    }
    data.datasets[0].data.push({x: distance, y: force})
    data.datasets[1].data.push({x: distance, y: speed})
    data.datasets[2].data.push({x: distance, y: blowing})
  }

  render () {
    // const styleAdd = this.props.logContainerRef ? {display : 'none'} : {}
    // DEBUG: show/hide graph in reports page
    const styleAdd = this.props.logContainerRef ? {} : {}
    return (
      <div className = 'content-center-and-right relative' style = {styleAdd}>
        <canvas width={this.props.width} height={this.props.height} id='myChart'></canvas>
      </div>
    )
  }
}

