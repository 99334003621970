import './style.scss'

import React from 'react'
import {withRouter} from 'react-router-dom'
import en from './en.json'
import {dateAndHoursToString} from '../../util/tools'
import {getReportsFromServer, getReportsFromIndexedDB, updateIndexedDB, getReportFromServer, getReportSelectedFromIndexedDB} from './databaseFetcher'
import {deleteReportFromServer, deleteReportFromIndexedDB} from './databaseDelete'
import {PdfButton} from './PdfButton.js'
import {ButtonHome} from '../../util/ButtonHome'
import {DeleteButton} from '../../util/DeleteButton.js'
const getMachineOrigin = require('../../util/get-machine-origin')

const text = en;
const apiAddress = getMachineOrigin()

const ReportLine = (props) => {
  const {reportId, reportDate,project, operator, client, section} = props.report;
  const isDeleteSelected = props.isDeleteSelected;
  const classToAdd = isDeleteSelected ? "warning" : "";
  const readableDate = dateAndHoursToString(reportDate, "en")
  return (
    <div
      className ={ "report-line grid-report-line "+classToAdd}
      onClick =  {() => props.handleClick(reportId)}>
      <h3 className = "item-grid-report-line-tl pos-align-center">
        {project}
      </h3>
      <p className = "item-grid-report-line-ml pos-align-center light-color">
        {section}
      </p>
      <p className = "item-grid-report-line-bl pos-align-center light-color">
        {client}
      </p>
      <p className = "item-grid-report-line-mr pos-align-center pos-justify-right light-color">
        {readableDate}
      </p>
      <p className = "item-grid-report-line-br pos-align-center pos-justify-right light-color">
        {operator}
      </p>
    </div>
  )
}

const ReportLineSelected = (props) => {
  const {reportId, reportDate,project, operator, client, section} = props.report;
  const settings = props.settings
  return (
    <div
      className = "report-line grid-report-line report-line--selected">
      <h3 className = "item-grid-report-line-tl pos-align-center">
        {project}
      </h3>
      <p className = "item-grid-report-line-ml pos-align-center light-color">
        {section}
      </p>
      <p className = "item-grid-report-line-bl pos-align-center light-color">
        {client}
      </p>
      <div className = "item-grid-report-line-mm btn btn--veryflat">
        {text.seeReport}
      </div>
      <PdfButton
        width={600}
        height={300}
        reportId={reportId}
        settings = {settings}
        />
    </div>
  )
}

class Reports extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      reports : [],
      reportsFromIndexedDB : [],
      selected : "",
      reportSelected : {},
      isDeleteSelected : false
    }
    this.handleSelect = this.handleSelect.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.gotoHome = this.gotoHome.bind(this)
    this.selectDelete = this.selectDelete.bind(this)
  }

  componentDidMount(){
    getReportsFromIndexedDB()
      .then(res => {
        this.setState({reportsFromIndexedDB : res.reports})
        getReportsFromServer()
          .then(res => {
            const reportsGotten = res.reports ? res.reports : [];
            this.setState({ reports : reportsGotten });
            updateIndexedDB(reportsGotten, this.state.reportsFromIndexedDB)
          })
          .catch(err => {
            console.error("got Data from IndexedDB because :", err);
            const reportsGotten = this.state.reportsFromIndexedDB ? this.state.reportsFromIndexedDB : [];
            this.setState({ reports : reportsGotten });
          })
      })
      .catch(err => console.error("Error fetching reports from indexedDB : ", err))
  }

  selectDelete () {
    let stateNow = this.state.isDeleteSelected
    this.setState({
      isDeleteSelected : !stateNow,
      selected : ""
    })
  }

  handleSelect(reportId){
    this.setState({
      selected : reportId
    });
  }

  handleDelete(reportId){
    const idToDelete = reportId;
    const isOnline = this.props.isOnline;
    if (idToDelete) {
      if (isOnline=== "server") {
        deleteReportFromServer(idToDelete)
          .then(() => {
            deleteReportFromIndexedDB(idToDelete)
              .then(() => {
                let newReports = this.state.reports;
                newReports = newReports.filter( item => {return (item.reportId !== idToDelete)});
                this.setState({ reports : newReports });
              })
          })
          .catch(err => {
            console.error("Delete problem")
          })
      } else {
        console.log("server not online");
      }
    }
  }

  gotoHome () {
    this.goto("/")()
  }

  goto (href) {
    return () => {
      this.props.history.push(href);
      this.props.updateLocation(href)
    }
  }

  render() {
    const {reports, reportSelected, isDeleteSelected} = this.state;
    const settings = this.props.settings;

    reports.sort(function(a,b){
      a = new Date(a.reportDate);
      b = new Date(b.reportDate);
      return a>b ? -1 : a<b ? 1 : 0;
    })

    const handleClick = this.state.isDeleteSelected ? this.handleDelete : this.handleSelect;

    const ReportLines = reports.map(report => {
      if (this.state.selected === report.reportId) {
        return (
          <ReportLineSelected
            report = {report}
            key = {report.reportId}
            handleSelect = {this.handleSelect}
            settings = {settings}
          />
        )
      } else {
        return (
          <ReportLine
            report = {report}
            key = {report.reportId}
            handleClick = {handleClick}
            isDeleteSelected = {isDeleteSelected}
          />
        )
      }
    })
    return (
      <div className = "grid-main">
        <div className = "header-right pos-align-center">
          <ButtonHome onClick = {this.gotoHome}/>
        </div>
        <div className = "content-left pos-align-top">
          <h2 className = "secondary-color">{text.title}</h2>
        </div>
        <div className = "content-right pos-align-bot btn--delete">
          <DeleteButton onClick = {this.selectDelete} selected = {this.state.isDeleteSelected} cancel = {this.state.isDeleteSelected}/>
        </div>
        <div className = "content-center">
          {ReportLines}
        </div>
      </div>
    )
  }
}

export default withRouter(Reports);
