import { openDb, deleteDb } from 'idb';

const getMachineOrigin = require('../../util/get-machine-origin')

const apiAddress = getMachineOrigin()
const reportDBName = 'reportsDataBase';
const reportStoreName = 'reports-store';
const version = 1;
const dbPromise = openDb(reportDBName, version, upgradeDb => {
  switch (upgradeDb.oldVersion) {
    case 0:
      upgradeDb.createObjectStore('reports-store');
  }
});

const fullReportsDBName = 'fullReportsDataBase';
const fullReportsStoreName = 'fullReports-store';
const db2version = 1;
const db2Promise = openDb(fullReportsDBName, db2version, upgradeDb => {
  switch (upgradeDb.oldVersion) {
    case 0:
      upgradeDb.createObjectStore(fullReportsStoreName);
  }
});

export function getReportsFromServer(){
  return fetch(apiAddress+"/getReports", {
    method: 'GET'
  })
    .then(results => {
      return results.json();
    })
}

export function getReportFromServer(reportId){
  return fetch(apiAddress+"/getReport", {
    method: 'POST',
    body: JSON.stringify({reportId}),
    headers: {"Content-Type": "application/json"}
  })
    .then((raw) => {
      return raw.json()
    })
    .then((response) => {
      return ({
        reportSelected: response.report,
        selected: response.report.reportId
      })
    })
}

export function getReportsFromIndexedDB(){
  return new Promise((resolve, reject) => {
    dbPromise.then(db => {
        return db.transaction(reportStoreName)
          .objectStore(reportStoreName).getAll();
      })
      .then(reports => resolve({reports}))
  })
}

export function getReportSelectedFromIndexedDB(reportId){
  return new Promise((resolve, reject) => {
    db2Promise.then(db => {
        return db.transaction(fullReportsStoreName)
          .objectStore(fullReportsStoreName).get(reportId);
        })
      .then(fullReport => {resolve({fullReport})})
  })

}

export function updateIndexedDB(reportsServer, reportsIndexedDB){
  const idsServer = reportsServer.map(report => report.reportId);
  const idsIndexedDB = reportsIndexedDB.map(report => report.reportId);
  const idsToUpdate = diff(idsServer, idsIndexedDB);
  const idsToDelete = diff(idsIndexedDB, idsServer);
  return new Promise((resolve, reject) => {

    if (idsToUpdate.length > 0 ) {
      const reports = reportsServer;

      // --- update the small reports database
      dbPromise.then(db => {
        const tx = db.transaction(reportStoreName, 'readwrite');
        reports.map(report => tx.objectStore(reportStoreName).put(report, report.reportId))
        return tx.complete;
      });

      // --- update all reports database
      const promises = idsToUpdate.map(id => {
        return getReportFromServer(id)
          .then(res => {return res.reportSelected})
      })

      Promise.all(promises).then(function (results) {
        const fullReportsToAdd = results;
        db2Promise.then(db => {
          const tx2 = db.transaction(fullReportsStoreName, 'readwrite');
          fullReportsToAdd.map(fullreport =>
            tx2.objectStore(fullReportsStoreName).put(fullreport, fullreport.reportId))
          return tx2.complete;
        });
      }).catch(err => console.error('couldnt update indexeddb',  err))

      resolve("updateIndexedDB")
    }

    else if (idsToDelete.length > 0) {
      dbPromise.then(db => {
        const tx4 = db.transaction(reportStoreName, 'readwrite');
        idsToDelete.map(id =>
          tx4.objectStore(reportStoreName).delete(id)
        )
        return tx4.complete;
      });

      db2Promise.then(db => {
        const tx3 = db.transaction(fullReportsStoreName, 'readwrite');
        idsToDelete.map(id =>
          tx3.objectStore(fullReportsStoreName).delete(id)
        )
        return tx3.complete;
      });

      resolve("updateIndexedDB")

    }
    else{
      resolve("no updated needed")
    }
  })
}

function diff (arr1, arr2) {
  return arr1.filter(function(i) {return arr2.indexOf(i) < 0;});
}
