import { openDb, deleteDb } from 'idb';

const getMachineOrigin = require('../../util/get-machine-origin')

const apiAddress = getMachineOrigin()
const reportDBName = 'reportsDataBase';
const reportStoreName = 'reports-store';
const version = 1;
const dbPromise = openDb(reportDBName, version, upgradeDb => {
  switch (upgradeDb.oldVersion) {
    case 0:
      upgradeDb.createObjectStore('reports-store');
  }
});

const fullReportsDBName = 'fullReportsDataBase';
const fullReportsStoreName = 'fullReports-store';
const db2version = 1;
const db2Promise = openDb(fullReportsDBName, db2version, upgradeDb => {
  switch (upgradeDb.oldVersion) {
    case 0:
      upgradeDb.createObjectStore(fullReportsStoreName);
  }
});

export function deleteReportFromServer(id){
  return fetch(apiAddress+"/deleteReport", {
    method: 'POST',
    body: JSON.stringify({id:id}),
    headers: {"Content-Type": "application/json"}
  })
    .then(results => {
      return results.json();
    })
}

export function deleteReportFromIndexedDB(id){
  return new Promise((resolve, reject) => {
    dbPromise.then(db => {
      const tx4 = db.transaction(reportStoreName, 'readwrite');
      tx4.objectStore(reportStoreName).delete(id)
      return tx4.complete;
    });

    db2Promise.then(db => {
      const tx3 = db.transaction(fullReportsStoreName, 'readwrite');
      tx3.objectStore(fullReportsStoreName).delete(id)
      return tx3.complete;
    });
    resolve("updateIndexedDB")
  })
}
