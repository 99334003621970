import React from 'react'
import {withRouter} from 'react-router-dom'
import en from './en.json'
const text =en;
import {ButtonHome} from '../../../util/ButtonHome'

class Validation extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      needCrashTest : false
    }
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoSave = this.gotoSave.bind(this);
    this.gotoHome = this.gotoHome.bind(this)
    this.gotoCancel = this.gotoCancel.bind(this);
  }

  componentDidMount() {
    const needCrashTest = (this.props.fmaxSource === "crashtest");
    this.setState({needCrashTest});
  }

  gotoHome () {
    this.goto("/")()
  }

  gotoCancel () {
    this.goto("/parameters/form/general")()
  }

  gotoNext () {
    if (this.state.needCrashTest) {
      this.goto("/crashtest")()
    } else{
      this.props.sendParametersToMachine("UpdateParameters")
      this.goto("/recording/chart")()
    };
  }

  gotoSave () {
    this.goto("/parameters/save-as-favorite")()
  }

  goto (href) {
    return () => {
      this.props.history.push(href);
      this.props.updateLocation(href)
    }
  }

  render() {
    const textNext = this.state.needCrashTest ? text.buttonCrashTest : text.buttonStart;
    let disableClass = "";
    let disableClassSave = "";
    let info = "";
    let onClickNext = this.gotoNext;
    switch (this.props.isOnline) {
      case "wifi":
        disableClass = "disable";
        info = <p>Tablet is connected on the wifi but machine server not online. Please wait...</p>
        onClickNext = () => {return false}
        break;
      case "no":
        disableClass = "disable";
        info = <p>Tablet not connected to the machine wifi</p>
        onClickNext = () => {return false}
    }
    if (!this.props.parameterNeeded) {
      disableClass = "disable";
      disableClassSave = "disable";
      info = <div><p>No parameters set. Please go back to home page and start again.</p><ButtonHome onClick = {this.gotoHome}/></div>
    }
    return (
      <div className = "grid-main background-blue">
        <div className = "content-center pos-align-top grid-modal modal">
          <div className="btn btn--flat item-grid-modal-close btn--closemodal" onClick={this.gotoCancel}> X </div>
          <div className = "item-grid-modal-top pos-align-center">
            <h2 className = "text-center">{text.text}</h2>
            {info}
          </div>
          <div className={"btn btn--flat item-grid-modal-bottom-left "+disableClassSave} onClick={this.gotoSave}> {text.buttonSave} </div>
          <div className={"btn btn--raised item-grid-modal-bottom-right pos-align-center "+disableClass} onClick={onClickNext}> {textNext} </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Validation);
