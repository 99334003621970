import React from 'react'
import {withRouter} from 'react-router-dom'
import en from './en.json'
const text =en;
import Input from '../../../util/Input'
import {openDb, deleteDb, upgradeDB } from 'idb';


class Save extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      favorite : {}
    }
    this.gotoCancel = this.gotoCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount(){
    const favorite = this.props.parameters;
    favorite["name"] = "";
    favorite["date"] = new Date();
    this.setState({favorite})
  }

  gotoCancel () {
    this.goto("/parameters/form/general")()
  }

  goto (href) {
    return () => {
      this.props.history.push(href);
      this.props.updateLocation(href)
    }
  }

  handleChange() {
    let newFavorite = this.state.favorite;
    newFavorite["name"] = event.target.value;
    this.setState({favorite : newFavorite})
  }

  handleSave() {
    const favorite = this.state.favorite;
    const favoriteDBName = 'favoriteDB';
    const version = 1;
    const dbPromise = openDb(favoriteDBName, version, upgradeDb => {
      switch (upgradeDb.oldVersion) {
        case 0:
          upgradeDb.createObjectStore('favorite-store');
      }
    });
    dbPromise.then(db => {
      const tx = db.transaction('favorite-store', 'readwrite');
      tx.objectStore('favorite-store').put(favorite, favorite.name);
      return tx.complete;
    });
    dbPromise.then(res => {
      console.log("then");
      this.goto("/parameters/choices")()
    })
  }

  render() {
    const textNext = this.state.needCrashTest ? text.buttonCrashTest : text.buttonStart;
    return (
      <div className = "grid-main background-blue">
        <div className = "content-center pos-align-top grid-modal modal">
          <h2 className = "item-grid-modal-top pos-align-center">{text.text}</h2>
          <div className = "item-grid-modal-middle pos-align-center">
            <Input
              inputdata = {text.input}
              label = {text.label}
              value = {this.state.favorite.name}
              handleChange = {this.handleChange}
            />
          </div>
          <div className="btn btn--flat item-grid-modal-bottom-left" onClick={this.gotoCancel}> {text.buttonCancel} </div>
          <div className="btn btn--raised item-grid-modal-bottom-right pos-align-center" onClick={this.handleSave}> {text.buttonSave} </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Save);
