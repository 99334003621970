import './style.scss'

import React from 'react'
import {withRouter} from 'react-router-dom'
import en from './en.json'
import inputs from './inputs.json'
import Input from '../../../util/Input'

const text = en;

class Finish extends React.Component {
  constructor (props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
  }

  goto (href) {
    return () => {
      this.props.history.push(href);
      this.props.updateLocation(href)
    }
  }

  handleSave() {
    this.props.sendParametersToMachine("UpdateParameters");
    this.goto("/reports")()
  }

  render() {
    const {parameters} = this.props;
    const form = inputs.map(input => {
      return(
        <Input
          inputdata = {input}
          label = {text.inputs[input.name]}
          value = {parameters[input.category][input.name]}
          handleChange = {((e) => this.props.updateParameter(e, input.category, input.type))}
          key = {text.inputs[input.name]}
        />
      )
    });
    return (
      <div className = "grid-main background-blue">
        <div className = "content-center pos-align-top grid-modal modal">
          <h2 className = "item-grid-modal-top pos-align-center">{text.title}</h2>
          <div className = "item-grid-modal-middle grid-inside-modal">
            <h4 className = "item-grid-inside-modal-t">{text.text}</h4>
            {form}
          </div>
          <div className="btn btn--raised item-grid-modal-bottom pos-align-center" onClick={this.handleSave}> {text.buttonSave} </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Finish);
