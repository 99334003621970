import React from 'react'


export class DeleteButton extends React.Component {
  constructor (props) {
    super(props);
  }

  render () {
    let color = "#CCCCCC";
    let classToAdd = "disable"
    if (this.props.selected) {
      color = "#002169";
      classToAdd = ""
    };
    const Icon = this.props.cancel ? <CancelIcon color = {color}/> : <DeleteIcon color = {color}/>
    return (
      <div onClick = {this.props.onClick} className = {"btn btn--veryflat deleteButton "+classToAdd}>
        {Icon}
      </div>
    )
  }
}

const DeleteIcon = (props) => {
  return (
    <svg viewBox="0 0 69 82" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path d="M180,39 L122.25,39 L127.5,104 L169.5,104 L174.75,39 L117,39 M149,55 L149,88 M162,55 L159,88 M135,55 L138,88 M138,39 L140.625,28 L156.375,28 L159,39" transform="translate(-114.000000, -25.000000)" stroke={props.color} strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" fill="#FFF"></path>
    </svg>
  )
}

const CancelIcon = (props) => {
  return (
    <svg width="58px" height="58px" viewBox="0 0 58 58" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="button-done" transform="translate(-129.000000, -31.000000)" fill={props.color} fillRule="nonzero">
        <path d="M154.157924,59.8640663 L130.524944,37.574806 C129.283296,36.4037552 128.680378,34.153633 129.170151,32.5186491 C129.659925,30.8836652 131.048329,30.5116434 132.289977,31.6826943 L158.094125,56.0196743 L182.846976,31.8441614 C184.067998,30.6516191 185.462683,30.9993533 185.980916,32.6255404 C186.49915,34.2517276 185.935593,36.5120294 184.714572,37.7045717 L162.09682,59.7947846 L182.775863,79.2980632 C185.095634,81.1140425 186.442461,82.7197088 186.816345,84.1150621 C187.37717,86.208092 184.478075,88.4602792 183.259854,87.3113237 L158.160619,63.6391766 L132.855716,88.3538653 C131.657733,89.5239067 128.719772,87.3226587 129.243984,85.2201598 C129.593459,83.8184939 130.912058,82.1895668 133.199782,80.3333785 L154.157924,59.8640663 Z"></path>
      </g>
    </svg>
  )
}
